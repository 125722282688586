<template>
	<div class='datainst-container'>
		<div class='title'>{{('用户服务协议')}}</div>
		<div class='content-title'>{{('一、服务条款的确认、接纳和修改')}}</div>
		<div class='content'>
			<div>1、用户，即：需要使用beegoplus服务的自然人、法人或组织；</div>
			<div>2、beegoplus，即：为用户提供国际集运服务的平台；</div>
			<div>3、用户服务的所有权和运作权归beegoplus所有。</div>
			<div>4、beegoplus所提供的服务均按照其发布的服务章程、服务条款、操作规则严格执行。</div>
			<div>5、注册成为beegoplus平台用户，即表示用户与beegoplus已对本协议内容达成一致，并接受beegoplus所有的服务条款。</div>
			<div>6、beegoplus保留随时修改服务条款的权利，一经向用户公布即对用户生效。</div>
		</div>
	
	</div>
</template>

<script>
	export default {
		data() {
			return {

			};
		}
	}
</script>

<style lang="scss">
.privacy-container,
.datainst-container {
  color: #333;
  padding: 30rpx;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #fff;
  .title {
    text-align: center;
    font-size: 50rpx;
    margin-bottom: 30rpx;
  }
  .h3 {
    text-indent: 2em;
    font-size: 30rpx;
    margin: 20rpx 0;
  }
  .content-title {
    font-size: 30rpx;
    background-color: #eaf1fa;
    color: #187ac7;
    text-indent: 16rpx;
    border-left: 6rpx solid #187ac7;
    line-height: 60rpx;
    margin: 30rpx 0;
  }
  .content {
    font-size: 24rpx;
    line-height: 46rpx;
  }
}
</style>

